enum Screen {
  Login,
  Lobby,
  PreGame,
  Game,
  History,
  HistoryFriends,
  HistoryOtherScores,
}
export default Screen;
