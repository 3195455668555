/* eslint-disable import/prefer-default-export */

export const amTestUser = (username: string): boolean =>
  username === "test" ||
  username === "test1" ||
  username === "test2" ||
  username === "test3" ||
  username === "test4" ||
  username === "test5" ||
  username === "test6";
