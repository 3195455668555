import Suit from "../types/Suit";

type DrawFunction = (ctx: CanvasRenderingContext2D, colors?: string[]) => void;
const shapeFunctions = new Map<string, DrawFunction>();
const scaleFactor = 3;

shapeFunctions.set("hanabiRed", (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(scaleFactor, scaleFactor);
  ctx.translate(-40.903844, -80.253155);
  ctx.moveTo(47.530531, 88.39269);
  ctx.bezierCurveTo(
    29.757962,
    90.07021,
    31.193616,
    78.98785,
    34.277276,
    72.11126,
  );
  ctx.bezierCurveTo(
    52.049847,
    70.433711,
    50.616495,
    81.5161,
    47.530531,
    88.39269,
  );
  ctx.moveTo(38.201088, 59.405974);
  ctx.bezierCurveTo(
    36.488459,
    59.405974,
    35.161286,
    62.901259,
    34.279579,
    66.24217,
  );
  ctx.bezierCurveTo(
    11.602243,
    72.595989,
    17.005567,
    101.10507,
    43.609027,
    101.10033,
  );
  ctx.bezierCurveTo(
    45.321658,
    101.10033,
    46.646522,
    97.60497,
    47.530531,
    94.26178,
  );
  ctx.bezierCurveTo(
    70.20557,
    87.91267,
    64.799917,
    59.401233,
    38.201088,
    59.405974,
  );
  ctx.scale(1 / scaleFactor, 1 / scaleFactor);
  ctx.closePath();
});

shapeFunctions.set("hanabiYellow", (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(scaleFactor, scaleFactor);
  ctx.translate(-58.029065, -70.265748);
  ctx.moveTo(48.471374, 76.245007);
  ctx.bezierCurveTo(
    52.478308,
    74.048764,
    57.340783,
    70.758733,
    60.960015,
    66.9381,
  );
  ctx.bezierCurveTo(
    60.918765,
    71.616441,
    61.423545,
    77.451433,
    63.024126,
    82.374519,
  );
  ctx.bezierCurveTo(
    59.019361,
    79.96819,
    53.639232,
    77.395132,
    48.471374,
    76.245007,
  );
  ctx.moveTo(78.213664, 83.015659);
  ctx.bezierCurveTo(
    74.748208,
    79.849062,
    67.217334,
    70.373217,
    64.219708,
    62.72108,
  );
  ctx.bezierCurveTo(
    64.353955,
    62.49395,
    64.505635,
    62.265928,
    64.629079,
    62.034484,
  );
  ctx.bezierCurveTo(
    64.629079,
    62.034484,
    66.385639,
    60.000655,
    63.054465,
    55.839957,
  );
  ctx.bezierCurveTo(
    59.727624,
    51.679247,
    56.955259,
    51.30884,
    56.214513,
    51.402032,
  );
  ctx.bezierCurveTo(
    55.473773,
    51.495022,
    54.735194,
    51.633868,
    54.460129,
    52.881296,
  );
  ctx.bezierCurveTo(
    53.420489,
    57.587859,
    48.768108,
    69.322751,
    43.468126,
    75.640741,
  );
  ctx.bezierCurveTo(
    43.271019,
    75.640741,
    43.069626,
    75.626481,
    42.876825,
    75.633641,
  );
  ctx.bezierCurveTo(
    42.876825,
    75.633641,
    40.236578,
    75.126788,
    38.300255,
    80.091092,
  );
  ctx.bezierCurveTo(
    36.359599,
    85.053189,
    37.4274,
    87.641479,
    37.875753,
    88.234939,
  );
  ctx.bezierCurveTo(
    38.324091,
    88.830219,
    38.815757,
    89.402319,
    40.032999,
    89.016809,
  );
  ctx.bezierCurveTo(
    44.609566,
    87.567769,
    57.020243,
    85.739789,
    65.144575,
    87.138969,
  );
  ctx.bezierCurveTo(
    65.166335,
    87.171069,
    65.183225,
    87.212469,
    65.205475,
    87.244869,
  );
  ctx.bezierCurveTo(
    65.205475,
    87.244869,
    66.084837,
    89.785519,
    71.354499,
    88.984149,
  );
  ctx.bezierCurveTo(
    76.622002,
    88.180599,
    78.326561,
    85.962679,
    78.618964,
    85.278249,
  );
  ctx.bezierCurveTo(
    78.90703,
    84.591659,
    79.16044,
    83.876899,
    78.213916,
    83.017049,
  );
  ctx.scale(1 / scaleFactor, 1 / scaleFactor);
  ctx.closePath();
});

shapeFunctions.set("hanabiGreen", (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(scaleFactor, scaleFactor);
  ctx.translate(-37.89188, -52.702171);
  ctx.moveTo(56.910015, 58.292302);
  ctx.bezierCurveTo(
    55.483093,
    57.138514,
    52.978323,
    54.51389,
    49.381539,
    52.702161,
  );
  ctx.bezierCurveTo(
    52.978323,
    50.88805,
    55.483093,
    48.26581,
    56.910015,
    47.112026,
  );
  ctx.bezierCurveTo(
    59.841692,
    44.740087,
    58.374671,
    43.555312,
    58.374671,
    43.555312,
  );
  ctx.bezierCurveTo(
    55.737819,
    38.372817,
    52.749542,
    35.352476,
    49.728244,
    32.591968,
  );
  ctx.bezierCurveTo(
    47.63857,
    30.684887,
    47.214037,
    32.868471,
    46.836666,
    34.568186,
  );
  ctx.bezierCurveTo(
    46.27771,
    37.083148,
    45.362573,
    39.512295,
    44.150277,
    41.779343,
  );
  ctx.bezierCurveTo(
    42.449773,
    44.964172,
    40.294055,
    47.336103,
    37.893058,
    49.669898,
  );
  ctx.bezierCurveTo(
    35.4897,
    47.336103,
    33.333993,
    44.964172,
    31.633476,
    41.779343,
  );
  ctx.bezierCurveTo(
    30.423545,
    39.512295,
    29.50607,
    37.083148,
    28.949451,
    34.568186,
  );
  ctx.bezierCurveTo(
    28.569708,
    32.868496,
    28.145185,
    30.684887,
    26.055515,
    32.591968,
  );
  ctx.bezierCurveTo(
    23.034217,
    35.352476,
    20.045939,
    38.372817,
    17.409083,
    43.555312,
  );
  ctx.bezierCurveTo(
    17.409083,
    43.555312,
    15.942073,
    44.740087,
    18.873743,
    47.112026,
  );
  ctx.bezierCurveTo(
    20.303023,
    48.26581,
    22.807798,
    50.88805,
    26.402219,
    52.702161,
  );
  ctx.bezierCurveTo(
    22.807798,
    54.51389,
    20.303023,
    57.138514,
    18.873743,
    58.292302,
  );
  ctx.bezierCurveTo(
    15.942073,
    60.661853,
    17.409083,
    61.846624,
    17.409083,
    61.846624,
  );
  ctx.bezierCurveTo(
    20.045939,
    67.031506,
    23.034217,
    70.051852,
    26.055515,
    72.812352,
  );
  ctx.bezierCurveTo(
    28.145185,
    74.719433,
    28.569729,
    72.535849,
    28.949451,
    70.836141,
  );
  ctx.bezierCurveTo(
    29.506055,
    68.321168,
    30.423545,
    65.892021,
    31.633476,
    63.622593,
  );
  ctx.bezierCurveTo(
    33.333993,
    60.440152,
    35.4897,
    58.065829,
    37.893058,
    55.734422,
  );
  ctx.bezierCurveTo(
    40.294055,
    58.065829,
    42.449773,
    60.440152,
    44.150277,
    63.622593,
  );
  ctx.bezierCurveTo(
    45.362573,
    65.892021,
    46.277689,
    68.321168,
    46.836666,
    70.836141,
  );
  ctx.bezierCurveTo(
    47.214058,
    72.53582,
    47.63857,
    74.719433,
    49.728244,
    72.812352,
  );
  ctx.bezierCurveTo(
    52.749542,
    70.051852,
    55.737819,
    67.031506,
    58.374671,
    61.846624,
  );
  ctx.bezierCurveTo(
    58.374671,
    61.846624,
    59.841692,
    60.661853,
    56.910015,
    58.292302,
  );
  ctx.scale(1 / scaleFactor, 1 / scaleFactor);
  ctx.closePath();
});

shapeFunctions.set("hanabiBlue", (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(scaleFactor, scaleFactor);
  ctx.translate(-99.694341, -76.779331);
  ctx.moveTo(56.910015, 58.292302);
  ctx.moveTo(115.29782, 63.128745);
  ctx.bezierCurveTo(
    115.50904,
    62.943572,
    115.73842,
    62.78373,
    115.9409,
    62.592635,
  );
  ctx.bezierCurveTo(
    116.13678,
    62.405678,
    117.7273,
    61.097621,
    118.49932,
    60.145425,
  );
  ctx.bezierCurveTo(
    118.99105,
    59.542738,
    118.50332,
    59.119802,
    118.07018,
    59.159833,
  );
  ctx.bezierCurveTo(
    85.907335,
    62.087565,
    79.611335,
    57.902868,
    79.611335,
    57.902868,
  );
  ctx.bezierCurveTo(
    76.59238,
    67.042068,
    83.408965,
    72.218957,
    83.408965,
    72.218957,
  );
  ctx.bezierCurveTo(
    83.423265,
    72.226057,
    83.506365,
    72.240367,
    83.548825,
    72.247497,
  );
  ctx.bezierCurveTo(
    81.513235,
    82.240997,
    82.770205,
    92.034227,
    83.053065,
    92.330067,
  );
  ctx.bezierCurveTo(
    83.053065,
    92.330067,
    88.939675,
    98.539287,
    97.700645,
    94.541477,
  );
  ctx.bezierCurveTo(
    97.700645,
    94.541477,
    94.483705,
    90.692697,
    92.968635,
    71.80037,
  );
  ctx.bezierCurveTo(
    97.351385,
    71.230615,
    102.62621,
    70.080615,
    107.50275,
    67.947093,
  );
  ctx.bezierCurveTo(
    103.98771,
    79.224239,
    105.57838,
    91.991907,
    105.9032,
    92.330067,
  );
  ctx.bezierCurveTo(
    105.9032,
    92.330067,
    111.79206,
    98.539287,
    120.54636,
    94.541477,
  );
  ctx.bezierCurveTo(
    120.54636,
    94.541477,
    116.38835,
    89.549157,
    115.29822,
    63.13056,
  );
  ctx.scale(1 / scaleFactor, 1 / scaleFactor);
  ctx.closePath();
});

shapeFunctions.set("hanabiWhite", (ctx: CanvasRenderingContext2D) => {
  ctx.scale(0.25, 0.25);
  ctx.beginPath();
  ctx.scale(scaleFactor, scaleFactor);
  ctx.translate(-240.36951, -310.42858);
  ctx.moveTo(209.32783, 329.84636);
  ctx.bezierCurveTo(
    216.84946,
    315.63443,
    219.83968,
    297.64058,
    220.32548,
    276.66186,
  );
  ctx.bezierCurveTo(
    234.19607,
    285.09913,
    251.98661,
    288.63517,
    272.94328,
    289.37289,
  );
  ctx.bezierCurveTo(
    264.65944,
    303.39826,
    261.20017,
    321.38368,
    260.47986,
    342.5574,
  );
  ctx.bezierCurveTo(
    246.93591,
    334.28974,
    229.66468,
    330.70283,
    209.32783,
    329.84636,
  );
  ctx.moveTo(304.8808, 333.66226);
  ctx.bezierCurveTo(
    303.08838,
    316.81313,
    302.35966,
    300.01488,
    303.78357,
    283.12336,
  );
  ctx.bezierCurveTo(
    305.86082,
    280.55403,
    307.97994,
    276.98406,
    309.91479,
    271.95565,
  );
  ctx.bezierCurveTo(
    317.41964,
    252.5287,
    313.29028,
    242.40397,
    311.55646,
    240.0805,
  );
  ctx.bezierCurveTo(
    309.82262,
    237.7486,
    307.92129,
    235.50997,
    303.20562,
    237.01935,
  );
  ctx.bezierCurveTo(
    290.6752,
    241.03873,
    277.24853,
    243.00603,
    264.20713,
    244.42212,
  );
  ctx.bezierCurveTo(
    247.48872,
    246.23679,
    230.80381,
    246.98295,
    214.01839,
    245.50752,
  );
  ctx.bezierCurveTo(
    212.56097,
    245.37999,
    211.01979,
    245.18546,
    209.47862,
    244.97296,
  );
  ctx.bezierCurveTo(
    207.65264,
    243.92995,
    205.54189,
    242.9039,
    203.05425,
    241.92028,
  );
  ctx.bezierCurveTo(
    183.86491,
    234.33097,
    173.86402,
    238.51145,
    171.56901,
    240.26675,
  );
  ctx.bezierCurveTo(
    169.26559,
    242.02204,
    167.05435,
    243.94694,
    168.54527,
    248.71252,
  );
  ctx.bezierCurveTo(
    172.51548,
    261.39808,
    174.4587,
    274.99949,
    175.8575,
    288.18538,
  );
  ctx.bezierCurveTo(
    177.54105,
    304.08479,
    178.21952,
    319.95026,
    177.10549,
    335.87509,
  );
  ctx.bezierCurveTo(
    174.97802,
    338.45291,
    172.80864,
    342.06526,
    170.82355,
    347.21242,
  );
  ctx.bezierCurveTo(
    163.31866,
    366.63936,
    167.44803,
    376.77261,
    169.1902,
    379.09604,
  );
  ctx.bezierCurveTo(
    170.92406,
    381.42791,
    172.81702,
    383.66655,
    177.53268,
    382.14868,
  );
  ctx.bezierCurveTo(
    190.06314,
    378.13781,
    203.48978,
    376.16204,
    216.52277,
    374.74595,
  );
  ctx.bezierCurveTo(
    233.20769,
    372.9398,
    249.85072,
    372.20204,
    266.57753,
    373.66055,
  );
  ctx.bezierCurveTo(
    269.12382,
    375.78047,
    272.66688,
    377.9428,
    277.67567,
    379.92701,
  );
  ctx.bezierCurveTo(
    296.86502,
    387.51635,
    306.87431,
    383.33586,
    309.16092,
    381.58054,
  );
  ctx.bezierCurveTo(
    311.47271,
    379.82528,
    313.67559,
    377.90038,
    312.19302,
    373.1348,
  );
  ctx.bezierCurveTo(
    308.21445,
    360.44921,
    306.27123,
    346.8478,
    304.8808,
    333.6619,
  );
  ctx.scale(1 / scaleFactor, 1 / scaleFactor);
  ctx.closePath();
  ctx.scale(4, 4);
});

shapeFunctions.set("hanabiAltWhite", (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(scaleFactor, scaleFactor);
  ctx.translate(-74.15539, -82.208377);
  ctx.transform(
    -3.299668,
    1.133672,
    -1.200981,
    -3.114738,
    480.92254,
    360.60904,
  );
  ctx.transform(0.282222, 0, 0, 0.282222, 74.155397, 112.54177);
  ctx.moveTo(28.347656, 0.3125);
  ctx.bezierCurveTo(
    27.66615,
    0.315561,
    26.899434,
    0.557678,
    26.056641,
    1.035156,
  );
  ctx.bezierCurveTo(
    24.331812,
    2.012365,
    22.721615,
    3.689235,
    20.039062,
    7.302734,
  );
  ctx.bezierCurveTo(
    16.394722,
    12.211809,
    16.585492,
    12.102236,
    9.349609,
    13.371094,
  );
  ctx.bezierCurveTo(
    3.625216,
    14.374948,
    1.072572,
    15.300497,
    0.476563,
    16.585938,
  );
  ctx.bezierCurveTo(
    -0.119634,
    17.871768,
    0.743099,
    19.083487,
    5.179688,
    23.181641,
  );
  ctx.bezierCurveTo(
    8.308318,
    26.071597,
    9.246576,
    27.098502,
    9.529297,
    27.947266,
  );
  ctx.bezierCurveTo(
    9.727531,
    28.542435,
    10.004965,
    31.05807,
    10.146484,
    33.537109,
  );
  ctx.bezierCurveTo(
    10.434059,
    38.574062,
    10.889828,
    40.396472,
    12.142578,
    41.515625,
  );
  ctx.lineTo(12.142578, 41.517625);
  ctx.bezierCurveTo(
    13.038688,
    42.318129,
    14.019224,
    42.435651,
    15.210938,
    41.882859,
  );
  ctx.bezierCurveTo(
    16.474513,
    41.296867,
    19.547233,
    38.243507,
    22.160156,
    34.978563,
  );
  ctx.bezierCurveTo(
    24.800264,
    31.679638,
    26.328861,
    30.274372,
    27.921875,
    29.679735,
  );
  ctx.bezierCurveTo(
    28.475513,
    29.473089,
    30.77206,
    28.951228,
    33.025391,
    28.521531,
  );
  ctx.bezierCurveTo(
    38.227387,
    27.529583,
    39.684318,
    27.140709,
    40.884766,
    26.418016,
  );
  ctx.bezierCurveTo(
    43.257594,
    24.989461,
    42.659074,
    23.724034,
    37.429688,
    19.119188,
  );
  ctx.bezierCurveTo(
    35.287418,
    17.23272,
    33.308731,
    15.289797,
    33.033203,
    14.802734,
  );
  ctx.bezierCurveTo(
    32.651245,
    14.127522,
    32.480019,
    12.921518,
    32.308594,
    9.722656,
  );
  ctx.bezierCurveTo(
    32.011232,
    4.173873,
    31.555509,
    2.353547,
    30.128906,
    1.017578,
  );
  ctx.bezierCurveTo(
    29.626019,
    0.545574,
    29.029163,
    0.309439,
    28.347656,
    0.3125,
  );
  ctx.moveTo(23.660156, 14.164062);
  ctx.bezierCurveTo(
    23.891052,
    14.162862,
    24.093294,
    14.243072,
    24.263672,
    14.402344,
  );
  ctx.bezierCurveTo(
    24.747004,
    14.85493,
    24.901206,
    15.47159,
    25.001953,
    17.351562,
  );
  ctx.bezierCurveTo(
    25.060063,
    18.435318,
    25.118645,
    18.84351,
    25.248047,
    19.072266,
  );
  ctx.bezierCurveTo(
    25.341377,
    19.237384,
    26.012481,
    19.89605,
    26.738281,
    20.535156,
  );
  ctx.bezierCurveTo(
    28.510006,
    22.095274,
    28.712119,
    22.523797,
    27.908203,
    23.007812,
  );
  ctx.bezierCurveTo(
    27.501492,
    23.252655,
    27.008535,
    23.38462,
    25.246094,
    23.720703,
  );
  ctx.bezierCurveTo(
    24.482663,
    23.866333,
    23.703201,
    24.043407,
    23.515625,
    24.113281,
  );
  ctx.bezierCurveTo(
    22.975912,
    24.314541,
    22.458926,
    24.790538,
    21.564453,
    25.908203,
  );
  ctx.bezierCurveTo(
    20.679193,
    27.014388,
    19.63904,
    28.04948,
    19.210938,
    28.248047,
  );
  ctx.bezierCurveTo(
    18.807185,
    28.435488,
    18.473523,
    28.394464,
    18.169922,
    28.123047,
  );
  ctx.bezierCurveTo(
    17.746338,
    27.743791,
    17.591485,
    27.126937,
    17.494141,
    25.421875,
  );
  ctx.bezierCurveTo(
    17.446201,
    24.582005,
    17.352337,
    23.728993,
    17.285156,
    23.527344,
  );
  ctx.bezierCurveTo(
    17.189386,
    23.239982,
    16.870531,
    22.891231,
    15.810547,
    21.912109,
  );
  ctx.bezierCurveTo(
    14.307426,
    20.523665,
    14.014804,
    20.113384,
    14.216797,
    19.677734,
  );
  ctx.bezierCurveTo(
    14.41873,
    19.242227,
    15.285177,
    18.92993,
    17.224609,
    18.589844,
  );
  ctx.bezierCurveTo(
    19.676131,
    18.159934,
    19.610992,
    18.196431,
    20.845703,
    16.533203,
  );
  ctx.bezierCurveTo(
    21.754551,
    15.308955,
    22.300389,
    14.73929,
    22.884766,
    14.408203,
  );
  ctx.bezierCurveTo(
    23.170303,
    14.246451,
    23.429261,
    14.165214,
    23.660156,
    14.164062,
  );
  ctx.scale(1 / scaleFactor, 1 / scaleFactor);
  ctx.closePath();
});

shapeFunctions.set("hanabiAltBlue", (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(scaleFactor, scaleFactor);
  ctx.translate(-110.38902, -72.869975);
  ctx.moveTo(110.59125, 92.432703);
  ctx.bezierCurveTo(
    110.33553,
    91.719873,
    109.71435,
    89.303503,
    109.21172,
    87.062783,
  );
  ctx.bezierCurveTo(
    107.74218,
    80.511323,
    107.00827,
    78.670889,
    105.86531,
    78.670889,
  );
  ctx.bezierCurveTo(
    105.00555,
    78.670889,
    101.64335,
    81.653913,
    99.324992,
    84.473613,
  );
  ctx.bezierCurveTo(
    95.926692,
    88.606593,
    95.838462,
    88.675133,
    93.975532,
    88.631263,
  );
  ctx.bezierCurveTo(
    92.231172,
    88.590163,
    90.310202,
    87.941073,
    89.701562,
    87.187223,
  );
  ctx.bezierCurveTo(
    89.131002,
    86.480403,
    89.897362,
    85.251163,
    93.121472,
    81.703793,
  );
  ctx.bezierCurveTo(
    97.905302,
    76.439922,
    98.141182,
    75.093343,
    94.937532,
    71.340155,
  );
  ctx.bezierCurveTo(
    93.214542,
    69.321734,
    92.383512,
    67.945448,
    92.383512,
    67.110557,
  );
  ctx.bezierCurveTo(
    92.383512,
    66.280178,
    95.395062,
    62.434319,
    95.776182,
    62.77802,
  );
  ctx.bezierCurveTo(
    95.909182,
    62.89796,
    96.790542,
    63.776806,
    97.734912,
    64.731146,
  );
  ctx.bezierCurveTo(
    101.31956,
    68.353598,
    103.59082,
    69.302262,
    105.34504,
    67.909572,
  );
  ctx.bezierCurveTo(
    106.12138,
    67.293254,
    106.14437,
    67.193035,
    105.95291,
    65.255957,
  );
  ctx.bezierCurveTo(
    105.84317,
    64.148763,
    105.47496,
    61.96546,
    105.13401,
    60.404152,
  );
  ctx.bezierCurveTo(
    103.8133,
    54.355863,
    104.76123,
    52.011272,
    108.527,
    52.011272,
  );
  ctx.bezierCurveTo(
    109.62447,
    52.011272,
    109.88835,
    52.120274,
    110.3947,
    52.780938,
  );
  ctx.bezierCurveTo(
    111.12564,
    53.735243,
    111.16906,
    53.924532,
    112.10994,
    60.262548,
  );
  ctx.bezierCurveTo(
    112.74106,
    64.513842,
    112.9829,
    65.512454,
    113.47639,
    65.904165,
  );
  ctx.bezierCurveTo(
    114.76289,
    66.925529,
    116.96789,
    65.447542,
    121.17964,
    60.740508,
  );
  ctx.bezierCurveTo(
    125.20739,
    56.239247,
    126.42293,
    55.508035,
    128.49635,
    56.339188,
  );
  ctx.bezierCurveTo(
    129.87137,
    56.890453,
    131.25118,
    57.930871,
    131.2477,
    58.413843,
  );
  ctx.bezierCurveTo(
    131.2467,
    58.625498,
    129.86146,
    60.430251,
    128.17062,
    62.423316,
  );
  ctx.bezierCurveTo(
    124.78415,
    66.414798,
    123.95978,
    67.936204,
    123.98136,
    70.154646,
  );
  ctx.bezierCurveTo(
    123.99556,
    71.758322,
    124.41928,
    72.583018,
    126.59104,
    75.25064,
  );
  ctx.bezierCurveTo(
    128.10791,
    77.113707,
    128.20625,
    78.221674,
    127.02241,
    80.107833,
  );
  ctx.bezierCurveTo(
    126.09803,
    81.580483,
    125.33823,
    82.027523,
    124.42142,
    81.637993,
  );
  ctx.bezierCurveTo(
    124.08597,
    81.495103,
    122.83083,
    80.446963,
    121.63278,
    79.308273,
  );
  ctx.bezierCurveTo(
    119.01173,
    76.81696,
    118.07777,
    76.202402,
    116.91278,
    76.202402,
  );
  ctx.bezierCurveTo(
    115.31439,
    76.202402,
    114.91852,
    77.084076,
    114.97754,
    80.512803,
  );
  ctx.bezierCurveTo(
    115.00594,
    82.147213,
    115.25798,
    84.428633,
    115.53717,
    85.582623,
  );
  ctx.bezierCurveTo(
    116.42716,
    89.254553,
    116.49962,
    90.696633,
    115.82469,
    91.302373,
  );
  ctx.bezierCurveTo(
    115.19506,
    91.867403,
    111.73271,
    93.728673,
    111.31127,
    93.728673,
  );
  ctx.bezierCurveTo(
    111.17162,
    93.728673,
    110.84776,
    93.145433,
    110.59146,
    92.432703,
  );
  ctx.scale(1 / scaleFactor, 1 / scaleFactor);
  ctx.closePath();
});

shapeFunctions.set("moon", (ctx: CanvasRenderingContext2D) => {
  ctx.scale(0.25, 0.25);
  ctx.beginPath();
  ctx.scale(scaleFactor, scaleFactor);
  ctx.translate(-414.89382, -203.53619);
  ctx.moveTo(374.94629, 141.08653);
  ctx.bezierCurveTo(
    340.46447,
    163.1565,
    330.40413,
    209.01371,
    352.47655,
    243.48828,
  );
  ctx.bezierCurveTo(
    374.54945,
    277.96545,
    420.41039,
    288.02439,
    454.89222,
    265.95519,
  );
  ctx.bezierCurveTo(
    477.02572,
    251.7884,
    489.04288,
    227.83433,
    489.01138,
    203.37139,
  );
  ctx.bezierCurveTo(
    485.2623,
    214.50946,
    477.9108,
    224.51267,
    467.2415,
    231.3414,
  );
  ctx.bezierCurveTo(
    442.36279,
    247.26542,
    409.21842,
    239.99458,
    393.29388,
    215.11947,
  );
  ctx.bezierCurveTo(
    377.36761,
    190.24411,
    384.68348,
    157.17502,
    409.56219,
    141.24994,
  );
  ctx.bezierCurveTo(
    418.14155,
    135.75865,
    427.65037,
    133.06436,
    437.13906,
    132.85839,
  );
  ctx.bezierCurveTo(
    416.92967,
    126.51122,
    394.18138,
    128.77283,
    374.94362,
    141.0852,
  );
  ctx.scale(1 / scaleFactor, 1 / scaleFactor);
  ctx.closePath();
  ctx.scale(4, 4);
});

shapeFunctions.set("hashtag", (ctx: CanvasRenderingContext2D) => {
  ctx.scale(1 / 3, 1 / 3);
  ctx.beginPath();
  ctx.scale(scaleFactor, scaleFactor);
  ctx.moveTo(66.660601, 59.539431);
  ctx.moveTo(-15.043961, -67.114874);
  ctx.bezierCurveTo(
    -18.3138,
    -67.114874,
    -21.483707,
    -65.817675,
    -23.969688,
    -63.46165,
  );
  ctx.bezierCurveTo(
    -26.452065,
    -61.108377,
    -27.975591,
    -57.961284,
    -28.261528,
    -54.598326,
  );
  ctx.lineTo(-29.398805, -41.194753);
  ctx.lineTo(-42.391592, -41.194753);
  ctx.bezierCurveTo(
    -45.661435,
    -41.194753,
    -48.831339,
    -39.896779,
    -51.317317,
    -37.540754,
  );
  ctx.bezierCurveTo(
    -53.799696,
    -35.187516,
    -55.324118,
    -32.040034,
    -55.609163,
    -28.677076,
  );
  ctx.bezierCurveTo(
    -55.905037,
    -25.1886,
    -54.844965,
    -21.891788,
    -52.62598,
    -19.395357,
  );
  ctx.bezierCurveTo(
    -50.414209,
    -16.908205,
    -47.338299,
    -15.538687,
    -43.96292,
    -15.538687,
  );
  ctx.lineTo(-31.577379, -15.538687);
  ctx.lineTo(-32.857175, -0.460797);
  ctx.lineTo(-45.849236, -0.460797);
  ctx.bezierCurveTo(
    -49.119082,
    -0.460797,
    -52.288623,
    0.836437,
    -54.774604,
    3.192462,
  );
  ctx.bezierCurveTo(
    -57.256979,
    5.545735,
    -58.780506,
    8.69297,
    -59.066446,
    12.05501,
  );
  ctx.bezierCurveTo(
    -59.362321,
    15.544475,
    -58.303512,
    18.840299,
    -56.083624,
    21.33673,
  );
  ctx.bezierCurveTo(
    -53.87276,
    23.823847,
    -50.795943,
    25.193435,
    -47.420567,
    25.193435,
  );
  ctx.lineTo(-35.033943, 25.193435);
  ctx.lineTo(-36.1189, 37.975907);
  ctx.bezierCurveTo(
    -36.414775,
    41.464418,
    -35.355966,
    44.760628,
    -33.136078,
    47.257978,
  );
  ctx.bezierCurveTo(
    -30.925217,
    49.745098,
    -27.848401,
    51.114648,
    -24.473021,
    51.114648,
  );
  ctx.bezierCurveTo(
    -21.203175,
    51.114648,
    -18.033454,
    49.817448,
    -15.548373,
    47.461388,
  );
  ctx.bezierCurveTo(
    -13.065097,
    45.108148,
    -11.540491,
    41.960878,
    -11.255451,
    38.598842,
  );
  ctx.bezierCurveTo(
    -11.253651,
    38.581202,
    -11.252051,
    38.563562,
    -11.251251,
    38.545922,
  );
  ctx.lineTo(-10.118306, 25.193326);
  ctx.lineTo(4.482971, 25.194526);
  ctx.lineTo(3.398737, 37.975093);
  ctx.bezierCurveTo(
    3.10198,
    41.464598,
    4.161855,
    44.760418,
    6.380836,
    47.256848,
  );
  ctx.bezierCurveTo(
    8.5917,
    49.744888,
    11.668513,
    51.114648,
    15.0439,
    51.114648,
  );
  ctx.bezierCurveTo(
    18.313735,
    51.114648,
    21.483283,
    49.817448,
    23.969264,
    47.461388,
  );
  ctx.bezierCurveTo(
    26.451636,
    45.108158,
    27.976242,
    41.961058,
    28.262186,
    38.598098,
  );
  ctx.bezierCurveTo(
    28.263107,
    38.587518,
    28.263886,
    38.576928,
    28.264686,
    38.566348,
  );
  ctx.lineTo(29.399434, 25.195514);
  ctx.lineTo(42.392238, 25.195514);
  ctx.bezierCurveTo(
    42.420438,
    25.195514,
    42.446238,
    25.195338,
    42.473338,
    25.194314,
  );
  ctx.bezierCurveTo(
    45.714308,
    25.173144,
    48.852658,
    23.877925,
    51.317898,
    21.541408,
  );
  ctx.bezierCurveTo(
    53.800258,
    19.18817,
    55.324708,
    16.0409,
    55.609758,
    12.678859,
  );
  ctx.bezierCurveTo(
    55.905738,
    9.190383,
    54.846418,
    5.894135,
    52.626558,
    3.396752,
  );
  ctx.bezierCurveTo(
    50.415668,
    0.909635,
    47.338888,
    -0.459918,
    43.956318,
    -0.459918,
  );
  ctx.lineTo(31.576863, -0.459918);
  ctx.lineTo(32.857019, -15.537773);
  ctx.lineTo(45.848718, -15.537773);
  ctx.bezierCurveTo(
    49.118578,
    -15.537773,
    52.288498,
    -16.835783,
    54.774448,
    -19.191772,
  );
  ctx.bezierCurveTo(
    57.256808,
    -21.544128,
    58.781228,
    -24.69228,
    59.066308,
    -28.054321,
  );
  ctx.bezierCurveTo(
    59.362288,
    -31.542868,
    58.303178,
    -34.838904,
    56.084168,
    -37.335334,
  );
  ctx.bezierCurveTo(
    53.872428,
    -39.823369,
    50.795438,
    -41.192745,
    47.412828,
    -41.192745,
  );
  ctx.lineTo(35.034535, -41.192745);
  ctx.lineTo(36.118762, -53.974477);
  ctx.bezierCurveTo(
    36.415448,
    -57.463941,
    35.356551,
    -60.759765,
    33.136659,
    -63.256196,
  );
  ctx.bezierCurveTo(
    30.925798,
    -65.743313,
    27.848985,
    -67.113995,
    24.473602,
    -67.113995,
  );
  ctx.bezierCurveTo(
    21.203756,
    -67.113995,
    18.033859,
    -65.81602,
    15.547875,
    -63.459995,
  );
  ctx.bezierCurveTo(
    13.065499,
    -61.107674,
    11.541073,
    -57.960405,
    11.256032,
    -54.597447,
  );
  ctx.lineTo(10.117675, -41.192745);
  ctx.lineTo(-4.483245, -41.192745);
  ctx.lineTo(-3.398285, -53.975218);
  ctx.bezierCurveTo(
    -3.10241,
    -57.463729,
    -4.161402,
    -60.759942,
    -6.380384,
    -63.257289,
  );
  ctx.bezierCurveTo(
    -8.592148,
    -65.744442,
    -11.668788,
    -67.113995,
    -15.044167,
    -67.113995,
  );
  ctx.moveTo(-9.834935, -22.679328);
  ctx.lineTo(11.719884, -22.679328);
  ctx.bezierCurveTo(
    12.691359,
    -22.679328,
    13.617742,
    -22.259064,
    14.27442,
    -21.520842,
  );
  ctx.bezierCurveTo(
    14.930188,
    -20.782585,
    15.255823,
    -19.794984,
    15.171032,
    -18.797364,
  );
  ctx.lineTo(13.285793, 3.420074);
  ctx.bezierCurveTo(
    13.128842,
    5.264713,
    11.630578,
    6.679104,
    9.834647,
    6.679104,
  );
  ctx.lineTo(-11.720175, 6.679104);
  ctx.bezierCurveTo(
    -12.691654,
    6.679104,
    -13.618037,
    6.259581,
    -14.274712,
    5.521358,
  );
  ctx.bezierCurveTo(
    -14.930483,
    4.783101,
    -15.256114,
    3.795888,
    -15.171328,
    2.798268,
  );
  ctx.lineTo(-13.285008, -19.420263);
  ctx.bezierCurveTo(
    -13.128058,
    -21.264055,
    -11.62997,
    -22.679328,
    -9.834935,
    -22.679328,
  );
  ctx.scale(1 / scaleFactor, 1 / scaleFactor);
  ctx.closePath();
  ctx.scale(3, 3);
});

shapeFunctions.set("kanji", (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(scaleFactor, scaleFactor);
  ctx.transform(1.0, 0.0, 0.0, 1.0, -9.007581, -241.9803);
  ctx.bezierCurveTo(
    -4.390911,
    222.14016,
    -5.018266,
    223.63299,
    -4.794479,
    225.27079,
  );
  ctx.bezierCurveTo(
    -4.861259,
    227.06524,
    -4.975042,
    229.19011,
    -3.414768,
    230.26666,
  );
  ctx.bezierCurveTo(
    -2.003446,
    230.94552,
    -0.143619,
    230.33956,
    1.429478,
    230.54164,
  );
  ctx.bezierCurveTo(
    5.248937,
    230.68794,
    9.134292,
    230.26105,
    12.919257,
    230.83865,
  );
  ctx.bezierCurveTo(
    11.667283,
    233.94539,
    10.089632,
    237.00552,
    8.064652,
    239.54602,
  );
  ctx.bezierCurveTo(
    6.861197,
    239.80496,
    5.560835,
    238.71351,
    4.315196,
    238.52916,
  );
  ctx.bezierCurveTo(
    2.330213,
    237.90147,
    -0.4248,
    236.80826,
    -1.8632,
    239.19195,
  );
  ctx.bezierCurveTo(
    -2.867403,
    240.92251,
    -1.83852,
    243.28888,
    -0.177014,
    243.88349,
  );
  ctx.bezierCurveTo(
    0.849583,
    244.53751,
    2.22981,
    244.75112,
    3.100987,
    245.5579,
  );
  ctx.bezierCurveTo(
    1.258662,
    248.09531,
    -1.541109,
    249.56324,
    -3.965663,
    251.30318,
  );
  ctx.bezierCurveTo(
    -6.163495,
    252.94583,
    -8.76801,
    253.7252,
    -10.961592,
    255.3244,
  );
  ctx.bezierCurveTo(
    -12.754129,
    256.71576,
    -11.735929,
    259.80205,
    -9.850583,
    260.30943,
  );
  ctx.bezierCurveTo(
    -7.236942,
    260.35313,
    -4.780344,
    258.87413,
    -2.32894,
    257.98896,
  );
  ctx.bezierCurveTo(
    2.09171,
    256.10039,
    6.117787,
    253.23949,
    9.95007,
    250.15175,
  );
  ctx.bezierCurveTo(
    11.712105,
    249.67187,
    12.937119,
    251.9695,
    14.342341,
    252.79864,
  );
  ctx.bezierCurveTo(
    17.772803,
    255.59709,
    20.523078,
    259.28147,
    23.634111,
    262.48744,
  );
  ctx.bezierCurveTo(
    25.397861,
    263.85397,
    26.99237,
    261.32574,
    28.235991,
    260.22052,
  );
  ctx.bezierCurveTo(
    29.376897,
    259.21826,
    30.641855,
    257.23842,
    29.403664,
    255.72135,
  );
  ctx.bezierCurveTo(
    25.636453,
    250.90643,
    20.847755,
    247.30673,
    16.123879,
    243.83466,
  );
  ctx.bezierCurveTo(
    16.865432,
    242.05993,
    18.472742,
    240.51866,
    19.439272,
    238.75624,
  );
  ctx.bezierCurveTo(
    22.45618,
    234.24125,
    24.232324,
    228.39921,
    23.381953,
    222.72677,
  );
  ctx.bezierCurveTo(
    22.866388,
    221.46851,
    21.778854,
    220.8183,
    20.555392,
    221.17473,
  );
  ctx.bezierCurveTo(
    12.638927,
    221.18673,
    4.707749,
    221.13103,
    -3.199645,
    221.20273,
  );
  ctx.scale(1 / scaleFactor, 1 / scaleFactor);
  ctx.closePath();
});

shapeFunctions.set("emptySet", (ctx: CanvasRenderingContext2D) => {
  ctx.scale(2 / 7, 2 / 7);
  ctx.beginPath();
  ctx.scale(scaleFactor, scaleFactor);
  ctx.moveTo(18.06696, -33.593032);
  ctx.bezierCurveTo(
    17.937116,
    -34.945908,
    17.624489,
    -35.265144,
    15.303533,
    -36.414909,
  );
  ctx.bezierCurveTo(
    13.864187,
    -37.127948,
    10.308861,
    -38.313716,
    7.402817,
    -39.049953,
  );
  ctx.bezierCurveTo(
    -6.425702,
    -42.553351,
    -19.453999,
    -39.314305,
    -29.359582,
    -29.910263,
  );
  ctx.bezierCurveTo(
    -37.501128,
    -22.180935,
    -42.050267,
    -10.684,
    -41.347053,
    0.385397,
  );
  ctx.bezierCurveTo(
    -41.013532,
    5.635746,
    -39.972946,
    9.549568,
    -37.152261,
    16.163069,
  );
  ctx.lineTo(-34.942449, 21.344279);
  ctx.lineTo(-8.364535, -5.361649);
  ctx.bezierCurveTo(
    17.139857,
    -30.988877,
    18.207467,
    -32.12919,
    18.06696,
    -33.593032,
  );
  ctx.moveTo(5.603533, 40.114884);
  ctx.bezierCurveTo(
    18.443087,
    38.252884,
    30.195076,
    29.803314,
    35.721066,
    18.460658,
  );
  ctx.bezierCurveTo(
    38.275666,
    13.217072,
    38.869516,
    10.218417,
    38.842216,
    2.700461,
  );
  ctx.bezierCurveTo(
    38.812316,
    -5.501334,
    37.619886,
    -11.694394,
    34.983536,
    -17.338568,
  );
  ctx.lineTo(33.192096, -21.173866);
  ctx.lineTo(19.886003, -7.94957);
  ctx.bezierCurveTo(
    5.092643,
    6.752854,
    -12.4197,
    24.558808,
    -16.754248,
    29.304984,
  );
  ctx.bezierCurveTo(
    -18.576124,
    31.299864,
    -19.583283,
    32.791904,
    -19.583283,
    33.495994,
  );
  ctx.bezierCurveTo(
    -19.583283,
    34.804184,
    -18.010991,
    36.027254,
    -14.540973,
    37.418404,
  );
  ctx.bezierCurveTo(
    -7.406745,
    40.278564,
    -1.228098,
    41.105614,
    5.603533,
    40.114884,
  );
  ctx.moveTo(-70.961668, 72.098354);
  ctx.bezierCurveTo(
    -72.007521,
    71.215564,
    -72.848172,
    69.767224,
    -73.254683,
    68.147744,
  );
  ctx.bezierCurveTo(
    -74.310054,
    63.943354,
    -73.110785,
    62.108514,
    -61.628224,
    50.359954,
  );
  ctx.bezierCurveTo(
    -56.064325,
    44.667154,
    -51.512034,
    39.823104,
    -51.512034,
    39.595414,
  );
  ctx.bezierCurveTo(
    -51.512034,
    39.367754,
    -52.884405,
    36.746844,
    -54.561735,
    33.771244,
  );
  ctx.bezierCurveTo(
    -58.102513,
    27.489874,
    -61.680206,
    17.244693,
    -63.009578,
    9.579806,
  );
  ctx.bezierCurveTo(
    -65.363319,
    -3.991429,
    -61.413282,
    -22.264582,
    -53.589285,
    -33.99913,
  );
  ctx.bezierCurveTo(
    -50.286636,
    -38.952503,
    -42.060615,
    -47.482443,
    -36.981141,
    -51.220879,
  );
  ctx.bezierCurveTo(
    -21.607703,
    -62.535576,
    0.53445,
    -65.806397,
    19.745235,
    -59.600455,
  );
  ctx.bezierCurveTo(
    22.204314,
    -58.806068,
    27.589996,
    -56.396781,
    31.713436,
    -54.246479,
  );
  ctx.lineTo(39.210586, -50.336856);
  ctx.lineTo(44.284316, -55.795686);
  ctx.bezierCurveTo(
    50.534776,
    -62.520561,
    59.178086,
    -70.298349,
    62.013226,
    -71.749269,
  );
  ctx.bezierCurveTo(
    63.178396,
    -72.345549,
    64.837846,
    -72.833439,
    65.700876,
    -72.833439,
  );
  ctx.bezierCurveTo(
    69.748316,
    -72.833439,
    73.929256,
    -67.197129,
    72.560576,
    -63.585937,
  );
  ctx.bezierCurveTo(
    71.640246,
    -61.157693,
    66.840296,
    -55.429127,
    58.367846,
    -46.647432,
  );
  ctx.lineTo(50.817656, -38.821666);
  ctx.lineTo(54.663266, -30.728187);
  ctx.bezierCurveTo(
    59.200806,
    -21.178501,
    61.029016,
    -15.9635,
    62.199266,
    -9.231772,
  );
  ctx.bezierCurveTo(
    64.012526,
    1.198819,
    62.785926,
    11.912655,
    58.527336,
    22.841222,
  );
  ctx.bezierCurveTo(
    48.469396,
    48.652304,
    26.793386,
    63.400694,
    -1.083752,
    63.400694,
  );
  ctx.bezierCurveTo(
    -12.913562,
    63.400694,
    -21.748215,
    61.004524,
    -33.291158,
    54.665244,
  );
  ctx.bezierCurveTo(
    -36.333509,
    52.994414,
    -39.085355,
    51.627384,
    -39.406377,
    51.627384,
  );
  ctx.bezierCurveTo(
    -39.727406,
    51.627384,
    -44.405839,
    56.008504,
    -49.802924,
    61.363214,
  );
  ctx.bezierCurveTo(
    -55.311645,
    66.828704,
    -60.647873,
    71.623884,
    -61.968783,
    72.295584,
  );
  ctx.bezierCurveTo(
    -65.257998,
    73.968204,
    -68.839478,
    73.889654,
    -70.961668,
    72.098364,
  );
  ctx.scale(1 / scaleFactor, 1 / scaleFactor);
  ctx.closePath();
  ctx.scale(3.5, 3.5);
});

shapeFunctions.set(
  "infinity",
  (ctx: CanvasRenderingContext2D, colors?: string[]) => {
    ctx.scale(1.2, 1.2);
    ctx.beginPath();
    ctx.scale(scaleFactor, scaleFactor);
    ctx.translate(-91.082135, -80.110718);
    ctx.transform(2.128652, 0.0, 0.0, 2.476848, -78.229708, -213.11753);
    ctx.moveTo(84.443554, 124.56058);
    ctx.bezierCurveTo(
      86.91878,
      124.46438,
      89.392846,
      122.02023,
      89.39211,
      117.58985,
    );
    ctx.bezierCurveTo(
      89.39211,
      117.08288,
      88.432933,
      116.69102,
      87.515841,
      116.42929,
    );
    ctx.bezierCurveTo(
      86.129485,
      111.08571,
      80.894815,
      111.01425,
      78.815855,
      115.1772,
    );
    ctx.bezierCurveTo(
      76.486172,
      109.95316,
      69.685595,
      111.83718,
      69.686815,
      119.18105,
    );
    ctx.bezierCurveTo(
      69.686815,
      119.68813,
      70.646062,
      120.08125,
      71.562529,
      120.34224,
    );
    ctx.bezierCurveTo(
      72.949924,
      125.68647,
      78.184271,
      125.75792,
      80.26307,
      121.59434,
    );
    ctx.bezierCurveTo(
      81.188027,
      123.66736,
      82.815125,
      124.62369,
      84.443554,
      124.56058,
    );
    ctx.moveTo(83.404271, 120.90682);
    ctx.bezierCurveTo(
      82.731479,
      120.87512,
      82.029116,
      120.63801,
      81.439628,
      120.35281,
    );
    ctx.bezierCurveTo(
      80.979448,
      115.09115,
      84.019975,
      115.51567,
      85.906337,
      116.42929,
    );
    ctx.bezierCurveTo(
      86.222708,
      120.04669,
      84.884415,
      120.97585,
      83.404271,
      120.90682,
    );
    ctx.moveTo(75.136667, 120.89622);
    ctx.bezierCurveTo(
      74.463876,
      120.86452,
      73.761523,
      120.62741,
      73.172033,
      120.34221,
    );
    ctx.bezierCurveTo(
      72.711854,
      115.08055,
      75.75237,
      115.50443,
      77.638733,
      116.41806,
    );
    ctx.bezierCurveTo(
      77.955104,
      120.03545,
      76.616822,
      120.96525,
      75.136667,
      120.89622,
    );
    ctx.transform(1 / 2.128652, 0.0, 0.0, 1 / 2.476848, 0, 0);
    ctx.scale(1 / scaleFactor, 1 / scaleFactor);
    ctx.closePath();
    ctx.scale(5 / 6, 5 / 6);
    if (colors) {
      // When multiple colors are passed, create a gradient
      const gradient = ctx.createLinearGradient(500, 0, 700, 0);
      for (let i = 0; i < colors.length; ++i) {
        gradient.addColorStop(i / (colors.length - 1), colors[i]);
      }
      ctx.fillStyle = gradient;
      ctx.fill();
    }
  },
);

shapeFunctions.set(
  "rainbow",
  (ctx: CanvasRenderingContext2D, colors?: string[]) => {
    ctx.scale(scaleFactor, scaleFactor);
    ctx.translate(-122.26724, -111.17504);

    // Yellow
    ctx.beginPath();
    ctx.moveTo(120.7045, 112.94754);
    ctx.lineTo(121.46755, 113.30924);
    ctx.lineTo(121.3414, 113.71376);
    ctx.bezierCurveTo(
      121.15357,
      114.31589,
      121.10436,
      114.72115,
      121.10401,
      115.67675,
    );
    ctx.bezierCurveTo(
      121.10314,
      117.42485,
      121.50972,
      119.54968,
      122.31769,
      122.02233,
    );
    ctx.bezierCurveTo(
      122.80175,
      123.50396,
      123.08836,
      124.22301,
      123.72124,
      125.54595,
    );
    ctx.bezierCurveTo(
      124.65053,
      127.48862,
      125.14967,
      128.29914,
      126.10263,
      129.41337,
    );
    ctx.bezierCurveTo(
      126.37052,
      129.72631,
      126.74133,
      130.15983,
      126.92629,
      130.37631,
    );
    ctx.bezierCurveTo(
      127.41552,
      130.94749,
      127.4699,
      131.13459,
      127.20076,
      131.31424,
    );
    ctx.bezierCurveTo(
      126.71153,
      131.64058,
      123.8357,
      131.5418,
      120.8336,
      131.09514,
    );
    ctx.bezierCurveTo(
      116.44076,
      130.44157,
      112.91681,
      129.22299,
      112.43589,
      128.19137,
    );
    ctx.bezierCurveTo(
      112.27959,
      127.85585,
      112.35309,
      126.61321,
      112.62095,
      125.0753,
    );
    ctx.bezierCurveTo(
      113.30471,
      121.14378,
      115.38625,
      116.9963,
      118.04566,
      114.2666,
    );
    ctx.bezierCurveTo(
      118.60219,
      113.69541,
      119.83322,
      112.58433,
      119.90867,
      112.58532,
    );
    ctx.bezierCurveTo(
      119.92597,
      112.58556,
      120.28531,
      112.74866,
      120.70491,
      112.94779,
    );
    if (colors) {
      ctx.fillStyle = colors[1];
      ctx.fill();
    }
    ctx.closePath();

    // Purple
    ctx.beginPath();
    ctx.moveTo(118.98989, 90.936255);
    ctx.bezierCurveTo(
      119.30421,
      91.041735,
      119.36909,
      91.109475,
      119.80241,
      91.787675,
    );
    ctx.bezierCurveTo(
      121.38788,
      94.269495,
      122.408,
      97.267715,
      122.81846,
      100.65265,
    );
    ctx.bezierCurveTo(
      122.95257,
      101.75978,
      122.95292,
      104.33859,
      122.81943,
      105.34959,
    );
    ctx.bezierCurveTo(
      122.7068,
      106.20206,
      122.51516,
      107.14097,
      122.32632,
      107.77196,
    );
    ctx.lineTo(122.187, 108.2371);
    ctx.lineTo(121.93055, 108.2896);
    ctx.bezierCurveTo(
      121.178,
      108.45294,
      120.44393,
      108.57959,
      120.38456,
      108.55666,
    );
    ctx.bezierCurveTo(
      120.34576,
      108.54246,
      120.28686,
      108.39791,
      120.25045,
      108.23597,
    );
    ctx.bezierCurveTo(
      119.92573,
      106.80133,
      118.03706,
      104.64461,
      115.18827,
      102.45536,
    );
    ctx.bezierCurveTo(
      112.24736,
      100.19528,
      108.83667,
      98.496875,
      106.75657,
      98.256765,
    );
    ctx.bezierCurveTo(
      105.97851,
      98.167165,
      105.38761,
      97.957585,
      105.26741,
      97.729075,
    );
    ctx.bezierCurveTo(
      105.07404,
      97.360785,
      105.66928,
      96.739035,
      107.18893,
      95.723645,
    );
    ctx.bezierCurveTo(
      110.26111,
      93.670755,
      114.32517,
      91.716045,
      116.86379,
      91.070105,
    );
    ctx.bezierCurveTo(
      117.65079,
      90.869705,
      118.61307,
      90.808665,
      118.98964,
      90.934275,
    );
    if (colors) {
      ctx.fillStyle = colors[4];
      ctx.fill();
    }
    ctx.closePath();

    // Red
    ctx.beginPath();
    ctx.moveTo(109.89501, 106.20943);
    ctx.bezierCurveTo(
      112.59828,
      106.44123,
      115.23204,
      107.15759,
      117.39617,
      108.24998,
    );
    ctx.bezierCurveTo(
      118.11217,
      108.6117,
      119.04574,
      109.16909,
      119.30826,
      109.39221,
    );
    ctx.lineTo(119.48119, 109.53897);
    ctx.lineTo(119.39629, 110.41939);
    ctx.bezierCurveTo(
      119.35089,
      110.90358,
      119.30059,
      111.31145,
      119.28782,
      111.32581,
    );
    ctx.bezierCurveTo(
      119.27392,
      111.34001,
      118.94299,
      111.37201,
      118.55093,
      111.39181,
    );
    ctx.bezierCurveTo(
      117.51581,
      111.44431,
      116.91173,
      111.63242,
      115.45747,
      112.34247,
    );
    ctx.bezierCurveTo(
      112.80601,
      113.63747,
      109.67307,
      116.01257,
      107.53951,
      118.34508,
    );
    ctx.bezierCurveTo(
      106.57178,
      119.40304,
      106.03965,
      120.15222,
      105.40936,
      121.34405,
    );
    ctx.bezierCurveTo(
      104.74276,
      122.60437,
      104.48537,
      122.82476,
      104.13106,
      122.43862,
    );
    ctx.bezierCurveTo(
      103.59847,
      121.85826,
      102.93822,
      119.46555,
      102.37867,
      116.08909,
    );
    ctx.bezierCurveTo(
      101.96058,
      113.56493,
      101.90042,
      112.89263,
      101.89782,
      110.69679,
    );
    ctx.bezierCurveTo(
      101.89582,
      108.89012,
      101.90082,
      108.74124,
      102.01496,
      108.2899,
    );
    ctx.bezierCurveTo(
      102.15843,
      107.70946,
      102.34384,
      107.32148,
      102.56827,
      107.12911,
    );
    ctx.bezierCurveTo(
      102.85556,
      106.88285,
      104.28019,
      106.52236,
      105.7631,
      106.32083,
    );
    ctx.bezierCurveTo(
      106.70617,
      106.19241,
      108.97362,
      106.13243,
      109.89577,
      106.21146,
    );
    if (colors) {
      ctx.fillStyle = colors[0];
      ctx.fill();
    }
    ctx.closePath();

    // Blue
    ctx.beginPath();
    ctx.moveTo(129.44717, 90.861735);
    ctx.bezierCurveTo(
      130.38835,
      91.250415,
      132.5268,
      93.071585,
      134.78309,
      95.405515,
    );
    ctx.bezierCurveTo(
      137.74753,
      98.471965,
      139.32158,
      100.77856,
      139.32158,
      102.0561,
    );
    ctx.bezierCurveTo(
      139.32158,
      102.33305,
      139.30428,
      102.37326,
      138.95077,
      102.83441,
    );
    ctx.bezierCurveTo(
      137.18025,
      105.15706,
      134.36365,
      107.32291,
      131.30942,
      108.71027,
    );
    ctx.bezierCurveTo(
      129.06072,
      109.73174,
      127.14008,
      110.2505,
      125.08442,
      110.39152,
    );
    ctx.bezierCurveTo(
      124.64113,
      110.42332,
      124.27344,
      110.43772,
      124.26768,
      110.43102,
    );
    ctx.bezierCurveTo(
      124.19008,
      110.3287,
      123.37773,
      108.85459,
      123.37877,
      108.8175,
    );
    ctx.bezierCurveTo(
      123.3795,
      108.7928,
      123.50561,
      108.67992,
      123.65878,
      108.57372,
    );
    ctx.bezierCurveTo(
      124.34067,
      108.1033,
      124.87797,
      107.45862,
      125.54474,
      106.31152,
    );
    ctx.bezierCurveTo(
      126.94677,
      103.89948,
      128.28038,
      99.923625,
      128.7263,
      96.826095,
    );
    ctx.bezierCurveTo(
      128.97998,
      95.063385,
      128.99176,
      94.014185,
      128.7779,
      92.039045,
    );
    ctx.bezierCurveTo(
      128.7131,
      91.440205,
      128.7391,
      90.948825,
      128.8361,
      90.824745,
    );
    ctx.bezierCurveTo(
      128.9228,
      90.718545,
      129.13172,
      90.730545,
      129.44442,
      90.856545,
    );
    if (colors) {
      ctx.fillStyle = colors[3];
      ctx.fill();
    }
    ctx.closePath();

    // Green
    ctx.beginPath();
    ctx.moveTo(142.64793, 111.57055);
    ctx.bezierCurveTo(
      142.94736,
      112.03636,
      142.44589,
      113.71633,
      141.13421,
      116.63704,
    );
    ctx.bezierCurveTo(
      139.03609,
      121.30935,
      136.6023,
      124.82813,
      135.2139,
      125.19663,
    );
    ctx.bezierCurveTo(
      134.98482,
      125.25583,
      134.93491,
      125.24913,
      134.34906,
      125.02941,
    );
    ctx.bezierCurveTo(
      130.46684,
      123.58104,
      126.57173,
      120.23338,
      124.27971,
      116.37526,
    );
    ctx.bezierCurveTo(
      123.69597,
      115.39236,
      123.06292,
      114.00644,
      122.81596,
      113.16963,
    );
    ctx.bezierCurveTo(
      122.79166,
      113.08223,
      122.91819,
      112.91349,
      123.42168,
      112.37388,
    );
    ctx.lineTo(124.05975, 111.6892);
    ctx.lineTo(124.38067, 111.94287);
    ctx.bezierCurveTo(
      125.36956,
      112.72524,
      127.16357,
      113.22322,
      129.9172,
      113.47975,
    );
    ctx.bezierCurveTo(
      130.85605,
      113.56715,
      133.7227,
      113.56685,
      134.70058,
      113.479,
    );
    ctx.bezierCurveTo(
      137.55626,
      113.22287,
      139.34497,
      112.77865,
      141.09571,
      111.89104,
    );
    ctx.bezierCurveTo(
      141.77596,
      111.546,
      141.98005,
      111.46679,
      142.23903,
      111.44771,
    );
    ctx.bezierCurveTo(
      142.53222,
      111.43011,
      142.56064,
      111.43351,
      142.648,
      111.57048,
    );
    if (colors) {
      ctx.fillStyle = colors[2];
      ctx.fill();
    }
    ctx.closePath();

    // Center
    ctx.beginPath();
    ctx.moveTo(121.05685, 112.245);
    ctx.lineTo(120.18283, 111.84147);
    ctx.lineTo(120.09893, 111.68376);
    ctx.lineTo(120.01503, 111.52606);
    ctx.lineTo(120.09513, 110.65795);
    ctx.bezierCurveTo(
      120.20082,
      109.51276,
      120.19143,
      109.56568,
      120.31103,
      109.42597,
    );
    ctx.lineTo(120.41291, 109.30672);
    ctx.lineTo(121.36619, 109.11656);
    ctx.lineTo(122.3195, 108.9264);
    ctx.lineTo(122.44044, 108.9861);
    ctx.bezierCurveTo(
      122.50694,
      109.0179,
      122.57941,
      109.0549,
      122.60124,
      109.0662,
    );
    ctx.bezierCurveTo(
      122.62204,
      109.0768,
      122.86775,
      109.47888,
      123.14495,
      109.95816,
    );
    ctx.lineTo(123.64874, 110.82958);
    ctx.lineTo(123.63144, 110.98623);
    ctx.bezierCurveTo(
      123.61414,
      111.1411,
      123.60714,
      111.15063,
      122.94546,
      111.86189,
    );
    ctx.bezierCurveTo(
      122.35967,
      112.49248,
      122.26365,
      112.58346,
      122.1645,
      112.60178,
    );
    ctx.bezierCurveTo(
      122.1025,
      112.61238,
      122.02449,
      112.62998,
      121.99156,
      112.63708,
    );
    ctx.bezierCurveTo(
      121.95656,
      112.64408,
      121.5644,
      112.48079,
      121.05746,
      112.24663,
    );
    if (colors) {
      ctx.fillStyle = "#000000";
      ctx.fill();
    }
    ctx.closePath();

    // External path
    ctx.beginPath();
    ctx.moveTo(143.09454, 111.19923);
    ctx.bezierCurveTo(
      142.98122,
      111.04258,
      142.75596,
      110.85066,
      142.34553,
      110.85066,
    );
    ctx.bezierCurveTo(
      141.66493,
      110.85066,
      140.83018,
      111.39083,
      140.81091,
      111.40402,
    );
    ctx.bezierCurveTo(
      139.03665,
      112.40409,
      135.84982,
      112.99891,
      132.29274,
      112.99891,
    );
    ctx.bezierCurveTo(
      128.76134,
      112.99891,
      125.71147,
      112.38884,
      124.7056,
      111.48023,
    );
    ctx.bezierCurveTo(
      124.51707,
      111.31053,
      124.33932,
      111.16377,
      124.16847,
      111.04216,
    );
    ctx.bezierCurveTo(
      124.19967,
      111.04216,
      124.23327,
      111.04916,
      124.26687,
      111.04916,
    );
    ctx.bezierCurveTo(
      129.70523,
      111.04916,
      136.34857,
      107.52382,
      139.71307,
      102.85018,
    );
    ctx.bezierCurveTo(
      139.93382,
      102.545,
      140.00453,
      102.14428,
      139.92447,
      101.65834,
    );
    ctx.bezierCurveTo(
      139.43655,
      98.710385,
      133.15703,
      92.616215,
      130.63796,
      90.833955,
    );
    ctx.bezierCurveTo(
      129.50576,
      90.032105,
      128.73952,
      89.938435,
      128.33938,
      90.461075,
    );
    ctx.bezierCurveTo(
      127.87923,
      91.075735,
      128.18309,
      92.332695,
      128.21705,
      92.456845,
    );
    ctx.bezierCurveTo(
      129.08604,
      97.041095,
      125.74936,
      106.61927,
      123.318,
      108.07475,
    );
    ctx.bezierCurveTo(
      123.07853,
      108.21835,
      122.87476,
      108.35983,
      122.69548,
      108.50165,
    );
    ctx.bezierCurveTo(
      124.44195,
      103.27682,
      123.32902,
      95.751145,
      119.88318,
      90.883595,
    );
    ctx.bezierCurveTo(
      119.59657,
      90.480445,
      119.08274,
      90.278155,
      118.35504,
      90.278155,
    );
    ctx.bezierCurveTo(
      115.14681,
      90.278155,
      108.09685,
      94.224045,
      105.85817,
      95.960535,
    );
    ctx.bezierCurveTo(
      104.91211,
      96.690485,
      104.53117,
      97.304885,
      104.69169,
      97.840905,
    );
    ctx.bezierCurveTo(
      104.94191,
      98.686325,
      106.46811,
      98.801755,
      106.62652,
      98.808365,
    );
    ctx.bezierCurveTo(
      111.17454,
      99.381485,
      119.09563,
      105.56922,
      119.70554,
      108.37119,
    );
    ctx.bezierCurveTo(
      119.76374,
      108.63261,
      119.83376,
      108.84619,
      119.90689,
      109.05752,
    );
    ctx.bezierCurveTo(
      117.01328,
      106.8874,
      112.64079,
      105.53871,
      108.27249,
      105.53871,
    );
    ctx.bezierCurveTo(
      106.22853,
      105.53871,
      104.3152,
      105.82377,
      102.5837,
      106.3928,
    );
    ctx.bezierCurveTo(
      102.22838,
      106.51064,
      101.93914,
      106.79133,
      101.72549,
      107.23163,
    );
    ctx.bezierCurveTo(
      100.41988,
      109.90072,
      101.9861,
      118.58771,
      102.99888,
      121.54,
    );
    ctx.bezierCurveTo(
      103.39495,
      122.68827,
      103.85501,
      123.25042,
      104.41359,
      123.25042,
    );
    ctx.bezierCurveTo(
      105.2719,
      123.25042,
      105.84119,
      121.82327,
      105.89681,
      121.67731,
    );
    ctx.bezierCurveTo(
      107.78024,
      117.57456,
      115.66494,
      111.94878,
      118.67623,
      111.94878,
    );
    ctx.bezierCurveTo(
      118.74273,
      111.94878,
      118.80688,
      111.95178,
      118.86891,
      111.95578,
    );
    ctx.bezierCurveTo(
      119.10457,
      111.97338,
      119.31608,
      111.98758,
      119.50699,
      111.98758,
    );
    ctx.bezierCurveTo(
      119.55859,
      111.98758,
      119.58569,
      111.96998,
      119.63522,
      111.96998,
    );
    ctx.bezierCurveTo(
      115.18773,
      115.14022,
      111.72265,
      121.89679,
      111.69056,
      127.90597,
    );
    ctx.bezierCurveTo(
      111.68856,
      128.2762,
      111.85552,
      128.63807,
      112.19145,
      128.97577,
    );
    ctx.bezierCurveTo(
      114.24611,
      131.07841,
      122.71295,
      132.21358,
      125.71783,
      132.21358,
    );
    ctx.lineTo(125.96388, 132.21158);
    ctx.bezierCurveTo(
      127.15166,
      132.18688,
      127.81519,
      131.91981,
      127.99494,
      131.38799,
    );
    ctx.bezierCurveTo(
      128.27981,
      130.55351,
      127.1132,
      129.54905,
      126.99332,
      129.44669,
    );
    ctx.bezierCurveTo(
      123.64809,
      126.25902,
      120.82294,
      116.51098,
      121.95091,
      113.87678,
    );
    ctx.bezierCurveTo(
      122.06423,
      113.61711,
      122.15018,
      113.382,
      122.21429,
      113.15562,
    );
    ctx.bezierCurveTo(
      123.80876,
      118.43266,
      129.04812,
      123.86451,
      134.64916,
      125.74485,
    );
    ctx.bezierCurveTo(
      134.76664,
      125.78435,
      134.89521,
      125.80405,
      135.0258,
      125.80405,
    );
    ctx.bezierCurveTo(
      137.76321,
      125.80405,
      142.17424,
      116.27816,
      143.07743,
      113.24964,
    );
    ctx.bezierCurveTo(
      143.37269,
      112.25607,
      143.37928,
      111.58497,
      143.09473,
      111.19717,
    );
    ctx.closePath();
    ctx.scale(1 / scaleFactor, 1 / scaleFactor);
  },
);

shapeFunctions.set("triangle", (ctx: CanvasRenderingContext2D) => {
  ctx.scale(150 / 42, 150 / 42);
  ctx.beginPath();
  ctx.transform(1.0, 0.0, 0.0, 1.0, 16.9774, -22.3191);
  ctx.moveTo(-16.977913, 37.72761);
  ctx.lineTo(-34.930431, 6.631814);
  ctx.lineTo(0.975572, 6.632296);
  ctx.closePath();
  ctx.moveTo(-16.997733, 26.070432);
  ctx.lineTo(-9.12009, 12.425835);
  ctx.lineTo(-24.875376, 12.425835);
  ctx.transform(1.0, 0.0, 0.0, 1.0, -16.9774, 22.3191);
  ctx.closePath();
  ctx.scale(42 / 150, 42 / 150);
});

export default function drawPip(
  ctx: CanvasRenderingContext2D,
  suit: Suit,
  shadow?: boolean,
  customFill?: string,
): void {
  // Each suit has a shape defined in the "suits.json" file (as the 'pip' property)
  const shapeFunction = shapeFunctions.get(suit.pip);
  if (!shapeFunction) {
    throw new Error(`Failed to find the shape function for pip "${suit.pip}".`);
  }

  // Handle the shadow
  if (shadow === true) {
    ctx.shadowColor = "rgba(0, 0, 0, 0.9)";
  }

  const hasCustomFill = customFill !== undefined && customFill !== "";
  if (hasCustomFill) {
    // The parent function has specified a custom fill color
    ctx.fillStyle = customFill!;
    shapeFunction(ctx);
    ctx.fill();
  } else if (suit.fill === "multi") {
    // Rainbow and omni cards have a multiple color fill which is passed as an array to the drawing
    // function; the drawing function will handle the filling
    shapeFunction(ctx, suit.fillColors);
  } else {
    // All other suits have a solid fill
    ctx.fillStyle = suit.fill;
    shapeFunction(ctx);
    ctx.fill();
  }

  // Draw a black border around the shape
  if (shadow === true) {
    ctx.lineWidth = hasCustomFill ? 8 : 5;
    ctx.shadowColor = "rgba(0, 0, 0, 0)";
  } else {
    ctx.lineWidth = 3;
  }
  ctx.stroke();
}
