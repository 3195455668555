type DrawFunction = (ctx: CanvasRenderingContext2D) => void;
const shapeFunctions = new Map<number, DrawFunction>();
const sf = 2.2; // Scale factor

shapeFunctions.set(0, (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
});

shapeFunctions.set(1, (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(sf, sf);
  ctx.translate(-49.06627, -129.96457);
  ctx.moveTo(49.037052, 114.06004);
  ctx.bezierCurveTo(
    47.692645,
    113.01401,
    41.624723,
    112.09276,
    45.702874,
    87.778647,
  );
  ctx.bezierCurveTo(
    48.099588,
    86.450447,
    47.194579,
    84.930047,
    47.110946,
    84.501897,
  );
  ctx.bezierCurveTo(
    47.071006,
    84.298337,
    46.832592,
    83.918907,
    46.491794,
    83.450827,
  );
  ctx.bezierCurveTo(
    46.590404,
    82.946547,
    46.682796,
    82.453447,
    46.788881,
    81.931667,
  );
  ctx.bezierCurveTo(
    46.788881,
    81.931667,
    47.335633,
    78.918277,
    45.823956,
    80.806957,
  );
  ctx.bezierCurveTo(
    45.594288,
    81.093777,
    45.355849,
    81.396157,
    45.113681,
    81.706967,
  );
  ctx.bezierCurveTo(
    44.262349,
    80.670887,
    43.394789,
    79.653537,
    43.092706,
    79.200407,
  );
  ctx.bezierCurveTo(
    42.452333,
    78.237989,
    41.984226,
    79.200407,
    41.984226,
    79.200407,
  );
  ctx.bezierCurveTo(
    41.357588,
    81.290037,
    36.162219,
    84.779017,
    28.95711,
    88.839667,
  );
  ctx.bezierCurveTo(
    27.274417,
    89.789637,
    28.95711,
    89.964377,
    28.95711,
    89.964377,
  );
  ctx.bezierCurveTo(
    33.759267,
    89.964377,
    37.318131,
    89.728357,
    39.963251,
    89.355237,
  );
  ctx.bezierCurveTo(
    35.976223,
    96.436757,
    32.795588,
    106.00863,
    35.38329,
    117.43419,
  );
  ctx.bezierCurveTo(
    35.38329,
    117.43419,
    36.185937,
    120.48624,
    38.436595,
    120.48624,
  );
  ctx.bezierCurveTo(
    39.824691,
    120.48624,
    41.029286,
    119.80217,
    42.183953,
    119.10439,
  );
  ctx.bezierCurveTo(
    44.192443,
    117.8948,
    46.157251,
    116.68648,
    48.334259,
    115.79515,
  );
  ctx.bezierCurveTo(
    49.139407,
    115.4667,
    50.059393,
    114.8552,
    49.037048,
    114.06004,
  );
  ctx.scale(1 / sf, 1 / sf);
  ctx.closePath();
});

shapeFunctions.set(2, (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(sf, sf);
  ctx.translate(-69.621888, -87.003819);
  ctx.moveTo(79.134002, 66.457149);
  ctx.bezierCurveTo(
    73.29348,
    64.177797,
    67.566172,
    64.741346,
    62.659021,
    66.337661,
  );
  ctx.bezierCurveTo(
    73.933762,
    53.421337,
    78.128924,
    40.497464,
    72.336202,
    36.994156,
  );
  ctx.bezierCurveTo(
    65.372355,
    32.785155,
    56.619746,
    38.917515,
    50.320082,
    48.163227,
  );
  ctx.bezierCurveTo(
    48.221873,
    51.243876,
    50.320082,
    50.268985,
    50.320082,
    50.268985,
  );
  ctx.bezierCurveTo(
    72.67081,
    35.973984,
    62.267812,
    58.290745,
    56.539244,
    68.99313,
  );
  ctx.bezierCurveTo(
    53.261104,
    70.763024,
    50.744005,
    72.675061,
    49.385452,
    73.741776,
  );
  ctx.bezierCurveTo(
    47.582851,
    75.159453,
    50.320082,
    74.388347,
    50.320082,
    74.388347,
  );
  ctx.bezierCurveTo(
    51.692474,
    73.849955,
    52.998196,
    73.444908,
    54.264921,
    73.10401,
  );
  ctx.bezierCurveTo(
    54.003252,
    73.563153,
    53.813328,
    73.895241,
    53.717727,
    74.065061,
  );
  ctx.bezierCurveTo(
    52.261057,
    76.65512,
    55.014643,
    74.065061,
    55.014643,
    74.065061,
  );
  ctx.bezierCurveTo(
    55.575676,
    73.563153,
    56.124127,
    73.058724,
    56.665032,
    72.551783,
  );
  ctx.bezierCurveTo(
    66.928403,
    70.609556,
    73.166429,
    74.794655,
    74.277173,
    76.816131,
  );
  ctx.bezierCurveTo(
    74.93255,
    78.011157,
    76.22066,
    76.816131,
    76.22066,
    76.816131,
  );
  ctx.bezierCurveTo(
    76.22066,
    76.816131,
    78.647188,
    71.960563,
    79.620822,
    69.334024,
  );
  ctx.bezierCurveTo(
    80.590672,
    66.707486,
    79.134002,
    66.457163,
    79.134002,
    66.457163,
  );
  ctx.scale(1 / sf, 1 / sf);
  ctx.closePath();
});

shapeFunctions.set(3, (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(sf, sf);
  ctx.translate(-81.640414, -140.58827);
  ctx.moveTo(88.098524, 108.79891);
  ctx.bezierCurveTo(
    86.781994,
    108.18406,
    85.321664,
    108.00429,
    83.810504,
    108.0861,
  );
  ctx.bezierCurveTo(
    87.618774,
    104.7179,
    89.522904,
    101.36836,
    88.916714,
    97.122457,
  );
  ctx.bezierCurveTo(
    88.253484,
    92.483627,
    84.823314,
    91.376607,
    80.638184,
    91.413747,
  );
  ctx.bezierCurveTo(
    81.191084,
    91.241607,
    81.740254,
    91.064167,
    82.253474,
    90.808837,
  );
  ctx.bezierCurveTo(
    83.333234,
    90.273287,
    84.236954,
    89.267887,
    82.379904,
    89.417917,
  );
  ctx.bezierCurveTo(
    76.397249,
    89.902607,
    69.341044,
    91.265027,
    64.837321,
    95.553037,
  );
  ctx.bezierCurveTo(
    62.491864,
    97.788147,
    63.79476,
    100.61339,
    65.758396,
    102.59066,
  );
  ctx.bezierCurveTo(
    66.736497,
    103.57743,
    67.704683,
    105.09602,
    69.033605,
    105.6229,
  );
  ctx.bezierCurveTo(
    71.629477,
    106.65182,
    70.774103,
    103.98279,
    71.088982,
    102.10964,
  );
  ctx.bezierCurveTo(
    71.450964,
    99.963777,
    72.705511,
    97.662917,
    74.320805,
    95.736507,
  );
  ctx.bezierCurveTo(
    77.841467,
    97.903427,
    82.056344,
    102.18901,
    78.306345,
    109.26007,
  );
  ctx.bezierCurveTo(
    76.5894,
    109.82537,
    74.945599,
    110.48612,
    73.515015,
    111.01296,
  );
  ctx.bezierCurveTo(
    69.948481,
    112.32578,
    73.515015,
    112.53035,
    73.515015,
    112.53035,
  );
  ctx.bezierCurveTo(
    74.324523,
    112.61715,
    75.058407,
    112.74235,
    75.743942,
    112.88857,
  );
  ctx.bezierCurveTo(
    75.037334,
    113.82084,
    74.568737,
    114.66132,
    76.077439,
    113.69685,
  );
  ctx.bezierCurveTo(
    76.352661,
    113.52082,
    76.612978,
    113.34867,
    76.881987,
    113.17371,
  );
  ctx.bezierCurveTo(
    86.274974,
    115.90349,
    82.420834,
    124.73735,
    80.359264,
    127.43735,
  );
  ctx.bezierCurveTo(
    75.016284,
    126.47168,
    67.765449,
    123.63156,
    66.622472,
    116.26422,
  );
  ctx.bezierCurveTo(
    66.622472,
    116.26422,
    66.668332,
    113.81961,
    65.220405,
    116.26422,
  );
  ctx.bezierCurveTo(
    63.089411,
    119.86795,
    60.78486,
    123.38611,
    60.78486,
    125.37083,
  );
  ctx.bezierCurveTo(
    60.78486,
    127.35428,
    63.119163,
    130.85638,
    68.372886,
    130.97413,
  );
  ctx.bezierCurveTo(
    73.62413,
    131.08949,
    82.146864,
    131.62,
    85.883234,
    129.37124,
  );
  ctx.bezierCurveTo(
    85.883234,
    129.37124,
    88.217534,
    127.47086,
    85.883234,
    127.82165,
  );
  ctx.bezierCurveTo(
    85.413404,
    127.89215,
    84.414224,
    127.90845,
    83.115054,
    127.80045,
  );
  ctx.bezierCurveTo(
    85.842324,
    125.84533,
    88.606794,
    123.47261,
    90.440264,
    120.89903,
  );
  ctx.bezierCurveTo(
    93.074564,
    117.20111,
    92.620844,
    110.90732,
    88.098534,
    108.7974,
  );
  ctx.scale(1 / sf, 1 / sf);
  ctx.closePath();
});

shapeFunctions.set(4, (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(sf, sf);
  ctx.translate(-86.004996, -130.16946);
  ctx.moveTo(93.144414, 103.07808);
  ctx.bezierCurveTo(
    93.932494,
    103.43924,
    94.378034,
    103.7408,
    94.586534,
    103.88727,
  );
  ctx.bezierCurveTo(
    95.544644,
    104.55743,
    95.925654,
    103.88727,
    95.925654,
    103.88727,
  );
  ctx.bezierCurveTo(
    96.774554,
    102.14854,
    98.250194,
    99.109121,
    97.392604,
    97.196631,
  );
  ctx.bezierCurveTo(
    96.701324,
    95.652731,
    94.788834,
    95.228281,
    93.136964,
    94.983791,
  );
  ctx.bezierCurveTo(
    93.386434,
    91.233281,
    93.974684,
    86.808811,
    95.065594,
    81.583931,
  );
  ctx.bezierCurveTo(
    95.065594,
    81.583931,
    95.348544,
    78.743105,
    94.011924,
    80.628271,
  );
  ctx.bezierCurveTo(
    92.607024,
    82.609031,
    89.103474,
    87.205971,
    86.786384,
    94.376911,
  );
  ctx.bezierCurveTo(
    85.901494,
    94.355411,
    85.020334,
    94.359011,
    84.152824,
    94.412711,
  );
  ctx.bezierCurveTo(
    80.830464,
    94.613771,
    77.791075,
    94.602681,
    74.601515,
    95.753071,
  );
  ctx.bezierCurveTo(
    73.989667,
    95.972741,
    73.396431,
    96.228391,
    72.80196,
    96.487771,
  );
  ctx.bezierCurveTo(
    75.743303,
    88.629321,
    78.359486,
    85.999471,
    80.111884,
    84.082001,
  );
  ctx.bezierCurveTo(
    80.949614,
    83.164871,
    79.786714,
    82.387951,
    79.786714,
    82.387951,
  );
  ctx.bezierCurveTo(
    79.786714,
    82.387951,
    77.464669,
    80.264441,
    74.730578,
    79.109011,
  );
  ctx.bezierCurveTo(
    71.996495,
    77.953577,
    71.557154,
    80.470481,
    71.557154,
    80.470481,
  );
  ctx.bezierCurveTo(
    70.532025,
    83.467641,
    69.897839,
    92.494021,
    69.748905,
    97.982041,
  );
  ctx.bezierCurveTo(
    68.468118,
    98.673321,
    67.212153,
    99.408021,
    65.967354,
    100.15514,
  );
  ctx.bezierCurveTo(
    63.837669,
    101.43221,
    66.540731,
    101.30442,
    66.540731,
    101.30442,
  );
  ctx.bezierCurveTo(
    67.645287,
    101.1978,
    68.7002,
    101.11337,
    69.722846,
    101.03752,
  );
  ctx.bezierCurveTo(
    69.729246,
    101.30442,
    69.737516,
    101.54015,
    69.748966,
    101.73377,
  );
  ctx.bezierCurveTo(
    69.952534,
    104.93822,
    70.894479,
    102.21779,
    70.894479,
    102.21779,
  );
  ctx.bezierCurveTo(
    71.028497,
    101.76976,
    71.160083,
    101.35776,
    71.292863,
    100.93331,
  );
  ctx.bezierCurveTo(
    77.261191,
    100.57215,
    81.782424,
    100.73725,
    85.174284,
    101.13544,
  );
  ctx.bezierCurveTo(
    84.418464,
    105.89993,
    84.305524,
    111.44258,
    85.397664,
    117.76705,
  );
  ctx.bezierCurveTo(
    85.397664,
    117.76705,
    86.930394,
    122.93613,
    91.332484,
    119.1087,
  );
  ctx.bezierCurveTo(
    95.734574,
    115.2787,
    96.691444,
    115.3743,
    96.691444,
    115.3743,
  );
  ctx.bezierCurveTo(
    96.691444,
    115.3743,
    97.362864,
    114.99202,
    96.691444,
    114.2263,
  );
  ctx.bezierCurveTo(
    96.280644,
    113.75595,
    93.791044,
    110.96475,
    93.144444,
    103.07896,
  );
  ctx.scale(1 / sf, 1 / sf);
  ctx.closePath();
});

shapeFunctions.set(5, (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(sf, sf);
  ctx.translate(-89.993569, -112.6882);
  ctx.moveTo(94.939252, 82.023243);
  ctx.bezierCurveTo(
    91.553592,
    80.541753,
    85.746642,
    81.163033,
    79.791422,
    82.155503,
  );
  ctx.bezierCurveTo(
    79.444642,
    77.560415,
    79.565942,
    74.24094,
    79.898002,
    71.760822,
  );
  ctx.bezierCurveTo(
    80.672432,
    72.057443,
    81.467682,
    72.315902,
    82.300922,
    72.493592,
  );
  ctx.bezierCurveTo(
    84.401182,
    72.940816,
    86.562702,
    73.021726,
    88.702172,
    72.856274,
  );
  ctx.bezierCurveTo(
    90.701962,
    72.700733,
    92.775262,
    72.558595,
    94.750522,
    72.194573,
  );
  ctx.bezierCurveTo(
    97.380132,
    71.708129,
    97.480612,
    69.201064,
    97.111782,
    67.125281,
  );
  ctx.bezierCurveTo(
    96.910812,
    65.997945,
    95.681792,
    60.122416,
    93.711422,
    61.79746,
  );
  ctx.bezierCurveTo(
    90.012082,
    64.942948,
    85.549332,
    67.081194,
    80.987342,
    67.00889,
  );
  ctx.bezierCurveTo(
    81.173602,
    66.448908,
    81.356172,
    65.945287,
    81.520372,
    65.48332,
  );
  ctx.bezierCurveTo(
    81.929642,
    64.327803,
    80.574402,
    64.06684,
    80.574402,
    64.06684,
  );
  ctx.bezierCurveTo(
    80.574402,
    64.06684,
    77.645801,
    63.027714,
    74.714756,
    63.027714,
  );
  ctx.bezierCurveTo(
    73.233298,
    63.027714,
    72.647582,
    63.655098,
    72.428242,
    64.27511,
  );
  ctx.bezierCurveTo(
    72.091272,
    64.18835,
    71.775129,
    64.167536,
    71.521481,
    64.28922,
  );
  ctx.bezierCurveTo(
    71.015409,
    64.530467,
    71.618301,
    65.441069,
    72.465004,
    66.396816,
  );
  ctx.bezierCurveTo(
    73.078903,
    70.157448,
    75.816348,
    78.037691,
    77.624971,
    82.599723,
  );
  ctx.bezierCurveTo(
    76.790503,
    82.973413,
    76.399616,
    83.780923,
    77.243885,
    83.725793,
  );
  ctx.bezierCurveTo(
    77.526927,
    83.704593,
    77.795294,
    83.704593,
    78.067324,
    83.697593,
  );
  ctx.bezierCurveTo(
    78.389587,
    84.474493,
    78.670195,
    85.115343,
    78.873602,
    85.523383,
  );
  ctx.bezierCurveTo(
    80.290112,
    88.358853,
    80.101412,
    85.523383,
    80.101412,
    85.523383,
  );
  ctx.bezierCurveTo(
    80.031612,
    84.894773,
    79.975182,
    84.302933,
    79.920052,
    83.714773,
  );
  ctx.bezierCurveTo(
    90.835512,
    84.405853,
    91.714092,
    94.468463,
    89.198442,
    98.144553,
  );
  ctx.bezierCurveTo(
    75.710958,
    96.718233,
    71.117103,
    90.602483,
    71.117103,
    90.602483,
  );
  ctx.bezierCurveTo(
    59.609799,
    113.28379,
    102.35261,
    98.426363,
    95.130382,
    98.441073,
  );
  ctx.bezierCurveTo(
    94.907332,
    98.442073,
    94.692922,
    98.438073,
    94.472362,
    98.434073,
  );
  ctx.bezierCurveTo(
    101.3564,
    93.063323,
    101.6027,
    84.941653,
    94.939222,
    82.026553,
  );
  ctx.scale(1 / sf, 1 / sf);
  ctx.closePath();
});

shapeFunctions.set(6, (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
});

shapeFunctions.set(7, (ctx: CanvasRenderingContext2D) => {
  ctx.beginPath();
  ctx.scale(sf, sf);
  ctx.translate(-82.570534, -140.29814);
  ctx.moveTo(69.064246, 130.78545);
  ctx.bezierCurveTo(
    68.937529,
    130.77135,
    68.507977,
    130.72725,
    68.109698,
    130.68805,
  );
  ctx.bezierCurveTo(
    66.622206,
    130.542,
    64.83195,
    130.0988,
    63.874417,
    129.63931,
  );
  ctx.bezierCurveTo(
    63.167663,
    129.30029,
    62.474124,
    128.63255,
    62.164103,
    127.9929,
  );
  ctx.bezierCurveTo(
    61.820322,
    127.2836,
    61.727503,
    126.81938,
    61.729747,
    125.8205,
  );
  ctx.bezierCurveTo(
    61.732047,
    124.77628,
    61.824187,
    124.2784,
    62.295919,
    122.75934,
  );
  ctx.bezierCurveTo(
    62.4758,
    122.18008,
    62.742256,
    121.3217,
    62.887981,
    120.85184,
  );
  ctx.bezierCurveTo(
    63.248026,
    119.69106,
    63.581651,
    119.0488,
    64.107349,
    118.50425,
  );
  ctx.bezierCurveTo(
    64.639781,
    117.95275,
    65.078961,
    117.73798,
    65.448089,
    117.84854,
  );
  ctx.bezierCurveTo(
    65.824651,
    117.96108,
    66.10132,
    118.33142,
    66.713321,
    119.54089,
  );
  ctx.bezierCurveTo(
    67.022742,
    120.15242,
    67.42537,
    120.86482,
    67.607996,
    121.12401,
  );
  ctx.bezierCurveTo(
    68.326769,
    122.14399,
    69.681021,
    123.38933,
    71.039174,
    124.27911,
  );
  ctx.bezierCurveTo(
    71.828344,
    124.79614,
    73.208557,
    125.55694,
    73.357559,
    125.55708,
  );
  ctx.bezierCurveTo(
    73.492567,
    125.55722,
    74.041443,
    124.65037,
    74.563161,
    123.5653,
  );
  ctx.bezierCurveTo(
    75.973166,
    120.63277,
    76.086379,
    117.67198,
    74.884118,
    115.1717,
  );
  ctx.bezierCurveTo(
    74.146958,
    113.63871,
    72.415408,
    111.73057,
    70.717851,
    110.58066,
  );
  ctx.bezierCurveTo(
    67.525358,
    108.41803,
    64.660973,
    104.05657,
    64.197092,
    100.65185,
  );
  ctx.bezierCurveTo(
    63.782317,
    97.607558,
    65.444791,
    95.177838,
    69.180977,
    93.367848,
  );
  ctx.bezierCurveTo(
    69.515057,
    93.205918,
    69.788389,
    93.055638,
    69.788389,
    93.033418,
  );
  ctx.bezierCurveTo(
    69.788389,
    93.012218,
    69.588434,
    93.022818,
    69.34403,
    93.054618,
  );
  ctx.bezierCurveTo(
    69.099626,
    93.086418,
    68.49975,
    93.104318,
    68.010952,
    93.091618,
  );
  ctx.bezierCurveTo(
    67.224788,
    93.070418,
    67.084259,
    93.048918,
    66.793084,
    92.904648,
  );
  ctx.bezierCurveTo(
    66.535345,
    92.776948,
    66.459498,
    92.702858,
    66.443447,
    92.563868,
  );
  ctx.bezierCurveTo(
    66.413817,
    92.308808,
    66.712439,
    92.138138,
    68.010956,
    91.667358,
  );
  ctx.bezierCurveTo(
    70.687815,
    90.696828,
    73.547745,
    89.913488,
    75.844833,
    89.521688,
  );
  ctx.bezierCurveTo(
    77.788734,
    89.190078,
    78.588918,
    89.123438,
    80.617582,
    89.124008,
  );
  ctx.bezierCurveTo(
    82.680842,
    89.124608,
    83.458942,
    89.196708,
    84.929502,
    89.524198,
  );
  ctx.bezierCurveTo(
    86.704942,
    89.919488,
    87.605702,
    90.315198,
    88.296462,
    91.003458,
  );
  ctx.bezierCurveTo(
    89.533142,
    92.235608,
    89.749792,
    94.250038,
    88.922312,
    96.822838,
  );
  ctx.bezierCurveTo(
    88.794222,
    97.221128,
    88.582172,
    97.917298,
    88.451012,
    98.369878,
  );
  ctx.bezierCurveTo(
    88.007492,
    99.900788,
    87.747342,
    100.51183,
    87.276832,
    101.12768,
  );
  ctx.bezierCurveTo(
    86.957112,
    101.54618,
    86.438032,
    101.95314,
    86.114462,
    102.03897,
  );
  ctx.bezierCurveTo(
    85.513962,
    102.19807,
    85.155702,
    101.83824,
    84.372852,
    100.28853,
  );
  ctx.bezierCurveTo(
    84.060182,
    99.669538,
    83.627832,
    98.925038,
    83.412112,
    98.634108,
  );
  ctx.bezierCurveTo(
    82.356552,
    97.210468,
    80.479252,
    95.706268,
    78.496285,
    94.695308,
  );
  ctx.lineTo(77.704025, 94.291378);
  ctx.lineTo(77.37305, 94.832968);
  ctx.bezierCurveTo(
    75.460292,
    97.962808,
    74.930201,
    101.02922,
    75.837763,
    103.71413,
  );
  ctx.bezierCurveTo(
    76.568464,
    105.87588,
    78.033294,
    107.63765,
    80.628672,
    109.4764,
  );
  ctx.bezierCurveTo(
    82.674212,
    110.92561,
    84.507042,
    113.14909,
    85.752622,
    115.69258,
  );
  ctx.bezierCurveTo(
    87.310462,
    118.87368,
    87.382222,
    121.32213,
    85.978262,
    123.39191,
  );
  ctx.bezierCurveTo(
    85.215372,
    124.51656,
    83.916252,
    125.53651,
    82.032942,
    126.48933,
  );
  ctx.bezierCurveTo(
    81.616562,
    126.69993,
    81.337392,
    126.87287,
    81.412572,
    126.8735,
  );
  ctx.bezierCurveTo(
    81.487772,
    126.87417,
    81.867942,
    126.8453,
    82.257452,
    126.8033,
  );
  ctx.bezierCurveTo(
    83.430962,
    126.68512,
    84.586172,
    126.91372,
    84.704822,
    127.28759,
  );
  ctx.bezierCurveTo(
    84.804622,
    127.60192,
    84.239022,
    127.87486,
    81.796542,
    128.69069,
  );
  ctx.bezierCurveTo(
    78.801293,
    129.69113,
    75.982324,
    130.34793,
    73.343261,
    130.66025,
  );
  ctx.bezierCurveTo(
    72.511406,
    130.75865,
    69.575512,
    130.84546,
    69.06425,
    130.78619,
  );
  ctx.scale(1 / sf, 1 / sf);
  ctx.closePath();
});

export default function drawStylizedRank(
  ctx: CanvasRenderingContext2D,
  rank: number,
): void {
  // Each rank has a shape
  const shapeFunction = shapeFunctions.get(rank);
  if (!shapeFunction) {
    throw new Error(`Failed to find the shape function for rank "${rank}".`);
  }

  // Move the cursor to the top-left hand corner
  ctx.translate(60, 135);

  // Draw the respective shape on the canvas
  shapeFunction(ctx);
}
